import { rootCertificates } from "tls";
import pre from "../Assets/preLaunch.png";
import { ImCheckmark } from "react-icons/im";
import Perlaunchmk from "../Assets/Perlaunchmk.png";
import perilaunchmk from "../Assets/perilaunchmk.png";
import postLaunchmk from "../Assets/postLaunchmk.png";
import prelaunchMan1 from "../Assets/prelaunchMan1.png";
import prelaunchHuman2 from "../Assets/prelaunchHuman2.png";
import perilaunchhuman from "../Assets/perilaunchhuman.png";
import PostLaunchhuman from "../Assets/PostLaunchhuman.png";
import Container from "react-bootstrap/Container";
import "./HomeSolution.scss";
import HeaderComponent from "./HeadingComp";
import { Card } from "react-bootstrap";
import config from "../config";

const FeatureSection: React.FC = () => {
  return (
    <>
     
      <div className="launch-readiness-section">
        <Container>
          <HeaderComponent
            firstHeading="We offer support at different phases of the "
            secondHeading="Product Life Cycle"
          />
          <Container>
            {config.launch?.map((items) => {
              return (
                <Card style={{ width: "18rem", alignItems: "center" }}>
                <img src={items.pic1} id= {items.id1} alt="preLaunch" />
                <img src={items.pic2} id={items.id2} alt="preLaunch" />
                {items.pic3 ?      <img src={items.pic3} alt="preMan2" id={items.id3} /> : null }  
                <Card.Body className={items.title}>
                  <Card.Title>{items.title}</Card.Title>
                  <Card.Text>
                 <section className="launch-section">
                  {items.phase?.map((para) => {
                    return (
                      <span
                      style={{
                        display: "flex",
                        textAlign: "left",
                      }}>
                        <span>
                        <ImCheckmark
                            style={{ color: "#F68D2A", width: 70 ,marginTop: 5 }}
                           
                          />
                        </span>
                       
                        <span style={{ textAlign: "left"}}>
                        {para}
                      </span>
                      </span>
                     
                    )
                  })}
                 </section>
                  </Card.Text>
                </Card.Body>
              </Card>
              )
            })}
          </Container>
        </Container>
      </div>
    </>
  );
};

export default FeatureSection;
