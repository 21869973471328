import React from "react";
import logo from "./logo.svg";
import "./App.scss";

import HomeHeader from "./Components/Header";
import HomePage from "./Pages/Home";
import Footer from "./Pages/Footer";
import PrivacyPage from "./Pages/PrivacyPage";

const ShowHomepage = () => {
  if (window.location.pathname === "/") {
    return (
      <>
        <HomePage />
      </>
    );
  }
};
const ShowPrivacyPage = () => {
  if (window.location.pathname === "/privacy-page") {
    return (
      <>
        <PrivacyPage />
      </>
    );
  }
};

function App() {
  return (
    <div className="App">
      <HomeHeader />
      {ShowHomepage()}
      {ShowPrivacyPage()}
      <Footer />
    </div>
  );
}

export default App;
