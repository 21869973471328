import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./Button.scss";
import "./Slider.scss";

const ControlledCarousel: React.FC = () => {
  return (
    <>
      <div className="hero-background">
        <Container>
          <Row>
            <Col xs={6} className="col-6-1">
              <div className="hero-left-col">
                <h2>We are a boutique life sciences consulting organization</h2>
                <p>
                  committed to delivering deep analytics and insights throughout
                  the product life cycle covering commercial and clinical
                  functions. Our mission is to make life sciences operations
                  efficient, reliable, and robust for the stakeholders we serve.
                </p>
                <div>
                  <div style={{ marginTop: 20 }}>
                    <Button
                      href="#services"
                      variant="outline-success"
                      className="btn-header bg-button">
                      &nbsp;&nbsp;&nbsp;Explore&nbsp;&nbsp;&nbsp;
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={6} className="col-6-2">
          
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ControlledCarousel;
