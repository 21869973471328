import pulse from "../../Assets/pulse.png";
import peak from "../../Assets/peak.png";
import "./Services.scss";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useEffect } from "react";
import { reveal } from "../../Hooks/Animation";
import HeaderComponent from "../HeadingComp";

const HomeServices: React.FC = () => {
  useEffect(() => {
    reveal(".our-services", ".serviceScroll");
    reveal(".icons-btns");
  }, []);
  return (
    <div className="our-services" id="services">
      <Container>
        <HeaderComponent firstHeading="" secondHeading="Our Products" />
        <div className="our-offerings-content">
          <Row>
            <Col xs={6} style={{ padding: 0, borderRight: "3px solid #ffff" }}>
              <div className="app-section">
                <div className="imgLogo">
                  <img width="90px" src={peak} alt="Logo" />
                </div>
                <div className="app-desc-section">
                  <h3>PEAK</h3>
                  <p>
                    360-degree performance, planning and coaching platform that
                    drives PEAK field performance
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={6} style={{ padding: 0 }}>
              <div className="app-section">
                <div className="imgLogo">
                  <img width="90px" src={pulse} alt="Logo" />
                </div>
                <div className="app-desc-section">
                  <h3>PULSE</h3>
                  <p>
                    Near real time data to insights through a prebuilt and
                    configurable out of the box data enablement, analytical and
                    visualization platform
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default HomeServices;
