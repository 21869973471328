import { useEffect } from "react";
import { reveal } from "../Hooks/Animation";
import Capability from "../Components/CapabilitySection";
import FeatureSection from "../Components/FeatureSection";
import HomeHeader from "../Components/Header";
import HomePageTopSlider from "../Components/HomeTopSlider";

import OurStorySection from "../Components/Section/OurStory";
import HomeServices from "../Components/Section/Services";
import "./HomeCss.scss";
import OurTeam from "../Components/Section/OurTeam";

const HomePage: React.FC = () => {
  useEffect(() => {
    reveal(".reveal", ".solutionScroll");
    reveal(".aboutReveal", ".AboutusScroll");
  }, []);

  return (
    <>
      <div className="acuitas-css">
        <div id="home">
          <HomePageTopSlider />
        </div>
        <Capability />
        <div>
          <FeatureSection />
        </div>
        <div >
          <HomeServices />
        </div>
        <div id="ourteam" className="reveal">
        <OurTeam />
        </div>
      </div>
    </>
  );
};

export default HomePage;
