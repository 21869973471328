import React, { useRef } from "react";

// import { Link } from "react-router-dom";
import config from "../config";
import FooterLogo from "../Assets/Footerlogo.png";
import fb from "../Assets/fb.svg";
import twitter from "../Assets/twitter.svg";
import linkedin from "../Assets/linkedin.svg";
import msg from "../Assets/msg.svg";
// import useScrollingAnimation from "../hooks/useScrollingAnimations";
import "./Styles/Footer.scss";
import { Col, Container, Row } from "react-bootstrap";

const Footer: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  // useScrollingAnimation("animate-contact-us", "hide-contact-us", ref);
  return (
    <>
    <div 
    style={{
      backgroundColor: "#1E3138",

      color: "#fff",
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",}}
      className="footer-new"
    >
      <Container>
      <Row>
        <Col xs={6} md={4}>
        <a href="#home">
                  <img alt="img" src={FooterLogo} className="logo" />
                </a>
        </Col>
        <Col xs={6} md={4}>
        <div
          style={{
            paddingTop: "20px",
            paddingBottom: 20,

            display: "flex",
          }}
          className="socialIcons">
          <div className="social-circle">
            <a
              rel="noreferrer"
              href="mailto:inquiry@acuitas360.com"
              target="_blank">
              <img alt="img" src={msg} />
            </a>
          </div>

          <div className="social-circle" style={{ marginLeft: "8px" }}>
            <a
              rel="noreferrer"
              href="https://twitter.com/acuitas360"
              target="_blank">
              <img alt="img" src={twitter} />
            </a>
          </div>
          <div className="social-circle" style={{ marginLeft: "8px" }}>
            <a
              rel="noreferrer"
              href="https://www.linkedin.com/company/acuitas360"
              target="_blank">
              <img alt="img" src={linkedin} />
            </a>
          </div>
        </div>
        </Col>
        <Col xs={6} md={4}>
        <span className="adresses">
          {config.address?.map((address) => (
            <div
              className="adressesLine"
              key={address.addressLine1}
              style={{  marginTop: 15 }}>
              <div className="adressline1">
                {address.addressLine1}
                <br></br>
                {address.country}
              </div>
            </div>
          ))}
        </span>
        </Col>
      </Row>

      </Container>
   
    </div>
            
      <div
        className="deep-footer"
        style={{
          backgroundColor: "#1E3138",

          color: "#fff",
        }}>
        <a href="/" className="link-privacy">
          <h3> © 2023 Acuitas360</h3>
        </a>
        |
        <a href="/privacy-page" className="link-privacy">
          <h3>Privacy Policy</h3>
        </a>
      </div>
    </>
  );
};

export default Footer;
