import { Config } from "./types/Config.type";
import Expertise from "./Assets/expertise.jpg";
import Value from "./Assets/value.png";
import perilaunchmk from "./Assets/perilaunchmk.png";
import postLaunchmk from "./Assets/postLaunchmk.png";
import Perlaunchmk from "./Assets/Perlaunchmk.png";
import perilaunchhuman from "./Assets/perilaunchhuman.png";
import PostLaunchhuman from "./Assets/PostLaunchhuman.png";
import prelaunchMan1 from "./Assets/prelaunchMan1.png";
import prelaunchHuman2 from "./Assets/prelaunchHuman2.png";
import Spirit from "./Assets/spirit.png";
import pic1 from "./Assets/principal.png";
import pic2 from "./Assets/D1.png";
import pic3 from "./Assets/D2.png";
import pic4 from "./Assets/D3.png";
import { SiDatabricks } from "react-icons/si";
import { FiUsers } from "react-icons/fi";
import { IoMdAnalytics } from "react-icons/io";
import { GiTrade } from "react-icons/gi";

const config: Config = {
  homeEntity1: [
    {
      title: "Expertise",
      smallDescription: "We deliver what you envision",
      longDescription:
        "We bring deep subject matter expertise in the areas we serve with a demonstrated history of industry and consulting experience. We understand what it takes to get ‘things done’ within the industry",
      icon: Expertise,
    },
    {
      title: "One team spirit",
      smallDescription: "We build sustainable solutions",
      longDescription:
        "We serve as trusted extensions of our client teams and can be relied upon for consistency and responsiveness all the time.",
      icon: Spirit,
    },
    {
      title: "Value",
      smallDescription: "We go above and beyond",
      longDescription:
        "We believe in value-added work and minimizing the middle project management layer. Our SMEs directly interface with the clients thereby allowing us to offer better pricing while ensuring efficiency",
      icon: Value,
    },
  ],

  email: "contactus@acuitas360.com",
  capbilitySubdescription:
    " We take pride in bringing deep expertise within our areas of offering and are willing to say ‘NO’ if we do not have the depth required",

  ourOfferingsLeft: [
    {
      iconComponent: <FiUsers />,
      offeringHeading: "Staffing",
      offeringSubheading: [ "We offer onshore & offshore Commercial Analytics Subject Matter Experts. They support US, EU5, and APAC regions, having 5+ years of relevant experience.",]
       
    },

  ],

  ourOfferingsRight: [
   
    {
      iconComponent: <SiDatabricks />,
      offeringHeading: "Master Data Management and Reporting",
      offeringSubheading: [
        "Build commercial data warehouse to ingest data from multiple sources and link it with the master data system using your preferred technology",
        "Collaborate with the analytics team to extract, transform, and load the datasets for downstream reporting",
        "Manage and govern the data to ensure accuracy and compliance"

      ]
        ,
    },
    {
      iconComponent: <IoMdAnalytics />,
      offeringHeading: "Brand Analytics",
      offeringSubheading: [

        "Patient Analytics: Understanding brand uptake, estimate patient actuals, identify and evaluate key events in the patient journey",
        "HCP Valuation: Identifying KOLs and key valued physicians who can be prospective medical engagement speakers and can drive sales",
        "Marketing Tactics: Measuring ROI, tracking execution of personal and non-personal tactics"
      ]
    },
    {
      iconComponent: <GiTrade />,
      offeringHeading: "Sales Analytics and Operations",
      offeringSubheading: [
        "Business Intelligence reporting for HQ and field force to track brand performance and field execution",
        "Incentive Compensation: Plan design, goal setting, evaluation and administration, reporting, year-end performance management, awards, contests, and SPFFs",
        "Sales force sizing and structure, territory alignments and people placement, segmentation and targeting"
      ]
       
    },
  ],

  TeamMember: [
    {
      name: "Ushank Agarwal",
      designation: "Principal",
      discriptions: [
        "17 years of experience in commercial strategy, analytics, and operations in the life sciences consulting space",
        "Proven expertise supporting launch brands with a focus on oncology and rare disease areas",
        "Specialize in business development, portfolio optimization, brand planning and patient journey mapping",
        "Enjoys professional networking, mentoring individuals and rooting for my alma mater Texas A&M - Gig’em Aggies",
      ],
       
      photoLink: pic1,
    },
    {
      name: "Hatim Gandhi",
      designation: "Director",
      discriptions: [
        "Experience in working with small and big pharma to support launch and growing brands with an emphasis on Oncology and orphan drugs",
        "Specializes in Sales Force Effectiveness - Incentive Compensation, Field Force Sizing and Alignments, and Launch Management and Tracking",
        "Holds a Mechanical Engineering degree from BITS, Pilani",
      ],
      photoLink: pic2,
    },
    {
      name: "Chaitanya Chowdary",
      designation: "Director",
      discriptions: [
        "Data architect, passionate about cloud computing, machine learning and data warehousing solutions",
        "Close to a decade of experiences in the life sciences industry focused on data architecture and enablement solutions",
        "Likes to stay informed about database solutions and is always interested in learning more",
        "Holds a Bachelor of Technology degree from IIT, Roorkee",
      ],
      photoLink: pic3,
    },
    {
      name: "Ganadhar Reddy",
      designation: "Director",
      discriptions: [
        "Proficient in delivering insights for the commercial leadership across different therapeutic areas and stages of the drug life cycle",
        "Specializes in Segmentation and Targeting, Brand Analytics, Patient Analytics, Forecasting and ROI measurement",
        "Graduated from National Institute of Technology, Warangal, India",
        "Enjoys Hiking, Trekking, Mountaineering"
      ],
      photoLink: pic4,
    },
  ],

  aboutSectionOnePoints: [
    "Data Requirements",
    "Data Vistualization",
    "Data Collection",
    "Business Intelligence",
  ],
  aboutSectionTwoPoints: [
    "Data Requirements",
    "Data Vistualization",
    "Data Collection",
    "Business Intelligence",
  ],

  address: [
    {
      addressLine1: "300 Carnegie Center Suite",
      country: "150 Princeton, NJ 08540",
    },
  ],

  

  launch: [
        {
            title: "Pre Launch",
            phase: [
              "Identify clinical trial sites and principal investigators, close to real time monitoring of patient enrollment by site",
              "Validate epidemiology inputs to forecasting, develop the end-to-end patient journey  ",
             "Map out the market landscape for the disease state and understand treatment regimens and sequencing"
            ],

            pic1: Perlaunchmk,
            pic2: prelaunchMan1,
            pic3: prelaunchHuman2,
            id1: "prelaunch",
            id2: "preMan1",
            id3: "preMan2"


        },
        {
          title: "Peri Launch",
          phase: [
            "Develop physician segmentation based on: number of patients treated and physician behavioural segments",
            "Develop predictive algorithms to identify the right physician for the right patient at the right time",
           "Support sales and marketing operations infrastructure build around CRM, PRC processes and Incentive Compensation"
          ],
          pic1: perilaunchmk,
        pic2: perilaunchhuman,
        id1: "periLaunch",
        id2: "periLaunchMan"

          
        

      },
      {
        title: "Post Launch",
        phase: [
          "Support CRM analytics, field reporting and Incentive Compensation design and administration",
          "Analyse physician adopters along with identifying patterns of adoption to inform brand strategy and messaging",
         "Calculate brand performance uptake, duration of therapy, drop off rates, market share, and other relevant KPIs"
        ],
        
        pic1: postLaunchmk,
        pic2: PostLaunchhuman,
        id1: "postLaunch",
        id2: "post-launch-human"

    },
  ],
  dataManagmentDescription: [
    "We build commercial data warehouse to ingest data from multiple sources and link it with the master data system using data warehouse of choice  (AWS, Azure,  Snowflake, Veeva)",
    "We work with your analytics team to extract, transform, and load your datasets for reporting",
    "We manage and govern your data to ensure accuracy and compliance",
    "Partnerships: Veeva Nitro and Tableau",
  ],
  brand: [
    "Forecasting, BD and asset valuation",
    "Patient Analytics: Understand brand uptake, brand opportunity, estimate patient actuals, patient shares, adherence, persistency, and other key metrics",
    "HCP Valuation and KOL identification: Support targeted tactical deployment by identifying key valued physicians who can drive sales",
    "Marketing Tactics: Monitor personal and non-personal tactic execution, measure ROI for marketing budgets spend on non-personal digital programs",
  ],
  staffing: [
    "Skilled SMEs – onshore and offshore",
    "Our USP: Skill, Cost and Business Continuity",
  ],

  sales: [
    "Business Intelligence Reporting: For HQ, develop insights dashboards to provide 360-view of the business & facilitate cross-functional leadership discussions. For field, develop solutions to track brand performance, field execution, and actionable insights",
    "Sales Force Effectiveness: Sales force sizing and structure, Territory alignment, Call planning",
    "Incentive Compensation: Plan Design, Goal (Quota) Setting, Administration, Reporting, Year-end Performance Management, Awards, Contests and SPIFFs",
  ],
  cuttingEdgeHeading: "Our team uses cutting-edge tools to address:",
  aboutUsSubDiscription:
    "How all this mistaken idea of denouncing pleasure and praising born and we will give you a complete. To take a trivial example, which of us ever undertakes laborious physical exercise.",

  cuttingEdge: [
    "Master data management",
    "Commercial data warehousing",
    "Data Integration and Aggregation",
    "Longitudinal claims data analysis",
    "Physician and Account segmentation and targeting",
    "CRM set up and configuration",
    "Incentive Compensation",
    "Field reporting and Business Intelligence",
    "Marketing operations and PRC process",
  ],
  preLaunchDescription: [
    "Identification of clinical trial sites and principal investigators.",
    "Close to real time monitoring of patient enrolment by site against targe.",
    "Forecasting: Validating epidemiology inputs- Incidence, Prevalence, de novo rates, treatment rates, and more.",
    "Map disease market landscape and understand treatment regimens and sequencing.",
    "Develop an end-to-end patient journey including procedures, surgeries, and drug treatment.",
    "Drive the value story for market access: look at the total cost of care including the need for inpatient care vs. outpatient vs. oral options.",
  ],

  periLaunchDescription: [
    "Perform physician segmentation using patient potential, and physician behaviour: early adopters, loyalists vs. generalists, treaters vs. referring physicians, and more.",
    "Generate predictive algorithms to identify the right physician for the right patient at the right time.",
    "Build commercial infrastructure for CRM, PRC processes and Incentive Compensation.",
  ],

  postLaunchDescription: [
    "Track utilization of drugs by care settings (community vs. hospitals vs. academic institutions) and by line of therapy.",
    "Analyse physician adopters and identify adoption patterns to inform brand strategy to fine-tune their messaging.",
    "Report brand performance uptake, duration of therapy, drop off rates, market share, and a lot more KPIs in a well-organized and visually appealing dashboard.",
    "Support CRM analytics, field reporting and Incentive Compensation design.",
  ],

  AboutUsOnHome: [
    "We are a boutique life sciences consulting organization committed to delivering deep analytics and insights throughout the product life cycle covering the commercial and clinical functions. Our mission is to make life sciences operations efficient, reliable, and robust for the stakeholders we serve.",
    "Our in-depth understanding of real-world data allows us to support identification of principal investigators that are actively treating patients in interest and can therefore accelerate clinical trial recruitment. In addition, we can identify, and balance recruitment of trial sites based on disease prevalence and eligible patient population.",
    "We strongly believe in holding ourselves to the value principles where each interaction with our stakeholders is focused on driving value. We hire subject matter experts who directly interact with our clients and lead the design and delivery of our engagements. This lowers the overhead costs and at the same time make each interaction productive and meaningful.",
    "In the area of commercial operations, we can support maintenance, scale up or set up the infrastructure and its services. Our areas of offering range from data enablement, insights and analytics, sales, and marketing operations.",
  ],
  SliderDescription:
    "We are a boutique life sciences consulting organization committed to delivering deep analytics and insights throughout the product life cycle covering the commercial and clinical functions. Our mission is to make life sciences operations efficient, reliable, and robust for the stakeholders we serve.",
};

export default config;
