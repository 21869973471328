import { Container } from "react-bootstrap";
import HeaderComponent from "../Components/HeadingComp";
import "./privacy.scss";

const PrivacyPage: React.FC = () => {
  return (
    <div id="privacy-policy">
      <Container className="privacy-container">
        <HeaderComponent firstHeading="Our" secondHeading="Privacy Policy" />

        <div>
          <br />
          <p>
            Privacy and security of users’ personal information is Acuitas360’s
            top priority. This Privacy Policy explains principles and practices
            related to our collection, usage, storage, security, sharing,
            transfer, access, control, and deletion of personal information
            within Acuitas360. Your usage of Acuitas360 products and services
            assumes full agreement with terms outlined in this document.
          </p>

          <p>
            Organizations allowed to use the platform through formal agreements
            with Acuitas360 are hereafter referred to as “clients”. Only users
            authorized by clients must use the platform. These users may be
            employees of the clients, employees of client's partners or any
            other user the client authorizes to access the platform.
          </p>

          <p>
            We collect information about you when you directly provide it to us
            through our products or services, when your company administrators
            provide it to us on your behalf or when it is automatically
            generated through your usage of our systems –
          </p>

          <span>
            <ul>
              <li>
                Your basic profile details provided by you, or your company
                representative required to access and use the product or
                services
              </li>
              <li>
                Content entered by you while using the product or services
              </li>
              <li>
                Information you choose to provide automatically through
                integrations of Acuitas360 platform with legacy applications
                owned by you, single sign on systems and other IT applications
              </li>
              <li>
                Information provided by you or your company representative to
                contacts within Acuitas360 via email and attachments with the
                aim of entering, modifying, cleaning, or deleting indicated data
                within the Acuitas360 platform on your behalf
              </li>
              <li>
                We keep track of your interactions with the system with the
                objective of maintaining secure audit trails as well as
                providing you better experience and value from our products
              </li>
              <li>
                Device and connection information including device type,
                operating system, browser type, IP address, URLs of
                referring/exit pages, device identifiers and crash data
              </li>
            </ul>
          </span>
        </div>
        <div className="sub-heading">
          <h3>
            Scope and consent for Personally Identifiable Information (PII)
          </h3>
        </div>
        <div>
          <ul>
            <li>
              Wherever information can be used to identify you, we collect such
              information only when it is necessary to deliver our product
              current features and services, is necessary for their future
              improvement or is required for legal and contractual reasons
            </li>
            <li>
              You give us your consent to do so – either directly or via your
              company representative
            </li>
          </ul>
          <p>
            Acuitas360 only collects information that is necessary to deliver
            product features, customized and easy user experience, security,
            maintain direct communication channels with users and perform R&D
            necessary to improve future value and security of its products and
            services. Specifically, we use this information for the following
            purposes:
          </p>
          <ul>
            <li>
              Personalization of user experience. For example, when you sign
              into the Acuitas360 platform using your email, username or via
              company SSO we identify you and show you information and actions
              relevant to you
            </li>
            <li>
              For research and development required to enhance future value and
              security of the platform. For example, we may analyse patterns of
              actions among multiple users and build features that can
              facilitate performing such actions. We may also build statistical
              models of data entry and platform usage to proactively identify
              and address data quality anomalies and potential security threats
            </li>
            <li>
              To provide analytics required by your company management for
              managing and improving the business processes our products help
              automate. For example, we may provide aggregated reports and
              dashboards to the HR department, leadership
            </li>
          </ul>
        </div>
        <div className="sub-heading">
          <h3>
            Security and storage of Personally Identifiable Information (PII)
          </h3>
        </div>
        <div>
          <p>
            Acuitas360 uses industry standard technology and best-practices
            designed to help keep your personal information safe. Key security
            measures include (but are not restricted to) robust encryption of
            all information transferred in and out of the platform,
            anonymization and encryption of sensitive information stored within
            the platform, strict access control of data and actions based on
            permissions assigned to a user by his/her administrator, extensive
            logging and monitoring of activity within the platform, proactive
            testing and hardening of all our systems, security-aware processes,
            employee screening and training etc. We also ensure on reasonable
            commercial efforts basis that any agent or third party that we
            employ complies with the same security standards as us for the
            protection of your data.
          </p>
          <p>
            While we implement safeguards designed to protect your information,
            it must be understood that no security system is impenetrable due to
            the inherent nature of the Internet.
          </p>
        </div>
        <div className="sub-heading">
          <h3>Storage</h3>
        </div>
        <div>
          <p>
            We use state-of-the-art cloud infrastructure providers to deliver
            our products and services as well as store data. We retain user and
            customer data within our systems only until there is a valid
            business or legal reason to do so. The actual duration of storage
            depends upon the kind of information as mentioned below. Beyond this
            period, we either permanently delete or anonymize your information.
            This happens separately for each medium of storage (e.g., databases,
            logs, backups etc.) depending upon the appropriate schedule for the
            medium.
          </p>
          <ul>
            <li>
              Your basic profile details are retained while you are a user of
              the system and for a reasonable period beyond that in case you
              want to re-activate your account. We may also retain some of this
              information if it may be required to comply with our legal
              obligations, to resolve disputes and to enforce our agreements.
              Where we retain information for product improvement and
              development, we take steps to eliminate information that directly
              identifies you, and we only use the information to uncover
              collective insights about the use of our products, not to
              specifically analyse personal characteristics about you
            </li>
            <li>
              Content entered by you, collected via system integrations, or
              provided to Acuitas360 via emails that is not registration or
              profile detail of users is deemed property of the client company.
              At the termination of agreement, such information may be provided
              back to the client, deleted, or retained for an agreed period
              depending upon client’s instruction. If no specific instruction is
              provided this information is retained for a reasonable period to
              allow re-instatement and, thereafter, anonymized or deleted
            </li>
            <li>
              Automatically generated information is anonymized and retained
              forever for R&D and future improvement of products
            </li>
          </ul>
        </div>
        <div className="sub-heading">
          <h3>Sharing of personal information with third parties</h3>
        </div>
        <div>
          <p>
            Acuitas360 does not sell your information to advertisers or other
            third parties.
          </p>
        </div>
        <div className="sub-heading">
          <h3>Sharing of data for legal or regulatory reasons</h3>
        </div>
        <div>
          <p>
            Acuitas360 cooperates with mandated government and law enforcement
            agencies whenever necessary. We will disclose any information about
            you to government or law enforcement officials if we believe it is
            necessary or appropriate to respond to claims and legal process, to
            protect our property and rights, to protect the safety of other
            persons or to prevent or stop any illegal, unethical, or legally
            actionable activity.
          </p>
        </div>
        <div className="sub-heading">
          <h3>Business transfers</h3>
        </div>
        <div>
          <p>
            Acuitas360 may sell, transfer, or otherwise share some or all of its
            assets in connection with a merger, acquisition, reorganization or
            sale of assets or in the event of bankruptcy. Should such a sale or
            transfer occur, management of all data stored by Acuitas360
            including your personal information will be transferred to the
            relevant entity.
          </p>
        </div>
        <div className="sub-heading">
          <h3>Accessing and controlling your information</h3>
        </div>
        <div>
          <p>
            You have certain choices available to you when it comes to your
            personal information. Below is a summary of those choices, how to
            exercise them and any limitations –
          </p>
          <ul>
            <li>
              You have the right to request a copy of your information, to
              object to our use of your information by providing a valid reason,
              to request the deletion or restriction of your information, or to
              request your information in a structured, electronic format. You
              can do so by sending a request to Acuitas360 support
            </li>
            <li>
              You or your administrator may deactivate your account at any time
              by contacting Acuitas360 support. You administrator may
              additionally disable your access to our products and contained
              data so that even though your account still exists you will be
              denied access to organizational data
            </li>
            <li>
              Your request and choices may be limited in certain cases. For
              example, if fulfilling your request would reveal information about
              another person, or if you ask to delete information which we or
              your administrator are permitted by law or have compelling
              legitimate interests to keep. Where you have asked us to share
              data with third parties, for example, by installing third-party
              apps, you will need to contact those third-party service providers
              directly to have your information deleted or otherwise restricted
            </li>
          </ul>
        </div>
        <div className="sub-heading">
          <h3>Your responsibilities</h3>
        </div>
        <div>
          <p>
            You are required to use the Acuitas360 platform in a manner that is
            lawful and compliant with policies of Acuitas360, relevant client
            and your employer. The platform provides extensive data collection
            and analysis functionality. A user can decide what information
            he/she want to store in the system. Users are required to take
            necessary consent and security measures when they use such tools
            before storing personal information about others or before storing
            any other sensitive data.
          </p>
          <p>
            Acuitas360 is not responsible for legal, contractual or policy
            violations resulting from the intentional or unintentional misuse of
            Acuitas360 functionality by the users of the platform.
          </p>
        </div>
        <div className="sub-heading">
          <h3>Actions of administrators</h3>
        </div>
        <div>
          <p>
            Acuitas360 builds products and services which is used to automate
            business processes and manage organizational data. Therefore, client
            administrators manage the way Acuitas360 products are configured and
            used by authorized users. To facilitate their job, Acuitas360
            products and services provide extensive functionality and services
            to such administrators including creation of user accounts,
            deactivation, or deletion of accounts, accessing information about
            users authorized by clients, viewing audit trails, allowing or
            disallowing usage of Acuitas360 products, changing information
            entered by other users, changing product configuration etc.
          </p>
          <p>
            Actions of administrators are governed by internal policies of our
            clients and Acuitas360 is not responsible for these actions. To
            better understand what these policies are please contact your system
            administrators or HR.
          </p>
        </div>
        <div className="sub-heading">
          <h3>Changes to this policy and additional terms</h3>
        </div>
        <div>
          <p>
            We may change this privacy policy from time to time. We will post
            any privacy policy changes on this page and, if the changes are
            significant, we will display a prominent notice on product home page
            or send you an email notification. We encourage you to review our
            privacy policy whenever you use the platform to stay informed about
            our information practices and the ways you can help protect your
            privacy.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default PrivacyPage;
