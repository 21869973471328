import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./Capability.scss";
import config from "../config";
import { useEffect } from "react";
import { reveal } from "../Hooks/Animation";
import HeaderComponent from "./HeadingComp";
import { Container } from "react-bootstrap";
import expertise from "../Assets/expertise.jpg"

const Capability: React.FC = () => {
  useEffect(() => {
    reveal(".capability-card", ".capability-card");
  }, []);
  return (
    <>
    <div className="value-prop">
        <Container>
        <HeaderComponent firstHeading="Our" secondHeading="Value Proposition" />
        <div className="Heading">
          <span>
            <h3
              style={{
                marginBottom: 20,
                textAlign: "center",
                fontSize: 20,
                opacity: 0.8,
              }}>
              {config.capbilitySubdescription}
            </h3>
          </span>
        </div>

        <Row  style={{marginTop: 25}}  xs={1} md={3} className="g-4 cards-overlay-in-row">
          {config.homeEntity1.map((item) => (
           <Col>
           <Card className="card-prop">
             <Card.Body>
               <Card.Text>
               <div className="value-card-icon">
                   <span
                     id="round-circle-id"
                     className="round-circle"></span>
                   <Card.Img variant="top" src={item.icon} />
                 </div>
                 <Card.Title className="cap-card-title">
                 {item.title} 
                 </Card.Title>
                 <p className="cap-long-description">
                 {item.longDescription}
                 </p>
               </Card.Text>
             </Card.Body>
           </Card>
         </Col>
          ))}
        
        </Row>
    </Container>
    </div>
     {/* <div className="capability-body" style={{ marginTop: 50}}>
      <Container>
        <HeaderComponent firstHeading="Our" secondHeading="Value Proposition" />
        <div className="Heading">
          <span>
            <h3
              style={{
                marginBottom: 20,
                textAlign: "center",
                fontSize: 20,
                opacity: 0.8,
              }}>
              {config.capbilitySubdescription}
            </h3>
          </span>
        </div>
        <Row xs={1} md={3} className="g-4 cards-overlay-in-row">
          {config.homeEntity1.map((item) => (
            <Col className="capability-card">
              <Card>
                <Card.Body>
                  <Card.Text>
                    <Card.Title className="cap-card-title">
                      {item.title}
                    </Card.Title>
                    <div className="capability-card-icon">
                      <span
                        id="round-circle-id"
                        className="round-circle"></span>
                      <Card.Img variant="top" src={item.icon} />
                    </div>
                    <p className="cap-long-description">
                      {item.longDescription}
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div> */}
    
    </>
   
  );
};

export default Capability;
