export const reveal = (classReveal, activeHeader) => {
  const isReveal = () => {
    const classNameToReveal = document.querySelectorAll(classReveal);
    const isactiveHeader = document?.querySelectorAll(activeHeader);
    for (let i = 0; i < classNameToReveal.length; i++) {
      const windowheight = window.innerHeight;
      const elementTop = classNameToReveal[i].getBoundingClientRect().top;
      const elementBottom = classNameToReveal[i].getBoundingClientRect().bottom;
      const elementVisible = 100;
      const elementLength = elementBottom - elementTop;
      if (elementTop < windowheight - elementVisible) {
        classNameToReveal[i].classList.add("active");
        isactiveHeader[i].classList.add("active");
      } else {
        classNameToReveal[i].classList.remove("active");
        isactiveHeader[i].classList.remove("active");
      }
      if (elementTop + elementLength < 0) {
        isactiveHeader[i].classList.remove("active");
      }
    }
  };
  window.addEventListener("scroll", isReveal);
};
