import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Image from "react-bootstrap/Image";
import HeaderLogo from "../Assets/HeaderLogo.png";

import Col from "react-bootstrap/Col";
import emailjs from "emailjs-com";
import "./Button.scss";
import "./Header.scss";
import { useEffect, useState } from "react";
import { FormGroup } from "react-bootstrap";

const HomeHeader: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [sideBarShow, setSideBarShow] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    emailjs
      .sendForm(
        "mkgmail",
        "template_du2hehf",
        event.target,
        "user_5jaFtdUXQF4WwPphRL2SY"
      )
      .then(
        (result) => {
          alert(
            "Your message has successfully submited, Your status is: " +
              result.text
          );
          handleClose();
        },
        (error) => {
          alert(error.text);
        }
      );

    setValidated(true);
  };

  const handleClose = () => setShow(false);
  const handleSideBarClose = () => setSideBarShow(false);
  const handleShow = () => {
    if (!show) {
      handleSideBarClose();
    }
    setShow(true);
  };

  const handleSideBarShow = () => setSideBarShow(true);

  useEffect(() => {
    window.onscroll = function () {
      scroll();
    };
    function scroll() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        document.getElementById("header")!.style.top = "-5px";
      } else {
        document.getElementById("header")!.style.top = "-90px";
      }
    }
  }, []);

  return (
    <div id="header">
      <Navbar
        bg="light"
        expand="md"
        className="mb-3 justify-content-center"
        style={{
          color: "#fff",
          backgroundColor: "transparent",
        }}
      >
        <Container fluid className="container-fluid-header">
          <Navbar.Brand href="/#home">
            <Image style={{ marginTop: 15 }} src={HeaderLogo} />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar-expand-md"
            onClick={handleSideBarShow}
          />
          <Navbar.Offcanvas
            show={sideBarShow}
            scroll="true"
            backdrop="true"
            id="offcanvasNavbar-expand-md"
            aria-labelledby="offcanvasNavbarLabel-expand-md"
            placement="end"
          >
            <Offcanvas.Header>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-md">
                <Navbar.Brand href="/#home">
                  <Image
                    style={{ marginTop: 15, width: 200 }}
                    src={HeaderLogo}
                  />
                </Navbar.Brand>
              </Offcanvas.Title>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={handleSideBarClose}
              ></button>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav
                className="justify-content-end flex-grow-1 pe-3"
                style={{ fontSize: 20 }}
              >
                <Nav.Link
                  className="home"
                  onClick={handleSideBarClose}
                  href="/#home"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  className="serviceScroll"
                  onClick={handleSideBarClose}
                  href="/#services"
                >
                  Our Products
                </Nav.Link>
                <Nav.Link
                  className="solutionScroll"
                  onClick={handleSideBarClose}
                  href="/#ourteam"
                >
                  Our Team
                </Nav.Link>
                {/* <Nav.Link
                  className="AboutusScroll"
                  onClick={handleSideBarClose}
                  href="#aboutus">
                  About Us
                </Nav.Link> */}
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="outline-success"
                  onClick={handleShow}
                  className="btn-header"
                >
                  Contact Us
                </Button>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {/* Modal */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  name="name"
                  type="text"
                  placeholder="Please enter your name"
                  defaultValue={value}
                  onChange={(e) => setValue(e.target!.value)}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  required
                  name="email"
                  type="email"
                  placeholder="Enter your e-mail ID"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target?.value)}
                />
                <Form.Control.Feedback>Enter e-mail</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Form.Group className="mb-3" controlId="validationCustom03">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                required
                name="subject"
                type="text"
                placeholder="Subject"
                defaultValue={subject}
                onChange={(e) => setSubject(e.target?.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom04">
              <Form.Label>Message</Form.Label>
              <Form.Control name="message" required as="textarea" rows={3} />
            </Form.Group>
            <Form.Group style={{ textAlign: "center" }}>
              <Button
                style={{ justifyContent: "center" }}
                type="submit"
                variant="outline-success"
                className="btn-header"
              >
                &nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HomeHeader;
