import "./HeadingComp.scss";
interface headingName {
  firstHeading?: string;
  secondHeading?: string;
}

const HeaderComponent: React.FC<headingName> = ({
  firstHeading,
  secondHeading,
}) => {
  return (
    <>
      <div className="our-offerings">
        <h3>
          {firstHeading}{" "}
          <span style={{ color: "#F68D2A" }}> {secondHeading}</span>
        </h3>
        <div className="bar-main">
          <div className="bar bar-big rotateIn"></div>
        </div>
      </div>
    </>
  );
};

export default HeaderComponent;
