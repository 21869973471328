import { Card, Container } from "react-bootstrap";
import config from "../../config";
import HeaderComponent from "../HeadingComp";
import "./OurTeam.scss";

const OurTeam: React.FC = () => {
  return (
    <>
      <div className="our-team"  >
        <HeaderComponent firstHeading="Our" secondHeading="Team" />
        <div className="Heading">
          <span>
            <h3
              style={{
                
                textAlign: "center",
                fontSize: 18,
                opacity: 0.8,
                width: "80%",
                margin: "auto",
                marginBottom: 30,
              }}>
              Our associates are our strength and drive our culture of ONE TEAM with high energy, engagement, enthusiasm and enjoyment. 
We are a performance driven organization vested in all round development of our associates as their progress through their career journey.            </h3>
          </span>
        </div>
        <Container>
          {config.TeamMember?.map((items) => {
            return (
              <Card>
                <div className="team-pic">
                  <Card.Img variant="top" src={items.photoLink} />
                </div>
                <Card.Body>
                  <Card.Title> {items.name}</Card.Title>
                  <Card.Title className="subHeading">
                    {items.designation}
                  </Card.Title>
                  <Card.Text
                    style={{
                     
                      textAlign: "left",
                      fontSize: 15,
                      opacity: 0.8,
                    }}>
                      <ul>
                      {items.discriptions?.map((para) => {
                            return (
                              <li>
                                    {para}
                              </li>
                                   
                            )     
                      })}
                      </ul>
                     
                 
                  </Card.Text>
                </Card.Body>
              </Card>
            );
          })}
        </Container>
      </div>
    </>
  );
};

export default OurTeam;
